/**
 * @ Author: caohenghui
 * @ Create Time: 2022-08-18 09:06:19
 * @ Modified by: Your name
 * @ Modified time: 2025-03-19 11:32:53
 * @ Description:
 */

import request from "server";
import { baseURL } from '../server/baseURL'
import axios from 'axios'
import { Method } from "axios";
import cookie from 'js-cookie'
import { message } from "antd";

//查询
export const storeManager = (data: Object) => {
    // let param = JSON.parse(JSON.stringify(data).replace('pageNo', 'pageNum'));
    return request({
        url: `/system/shop/queryPage`,
        method: 'post',
        data
    })
}
export const currentStoreManager = (data: Object) => {
    // let param = JSON.parse(JSON.stringify(data).replace('pageNo', 'pageNum'));
    return request({
        url: `/system/shop/queryWithPermission`,
        method: 'post',
        data
    })
}
// 添加修改门店
export const addStore = (data: object, method?: Method) => {
    return request({
        url: `/system/shop`,
        method: method || 'post',  //新增post  修改 put
        data
    })
}
// 区域
export const getAreaList = (data?: any) => {
    return request({
        url: `/system/shopArea/list`,
        method: 'get',
        data
    })
}
//获取数据详情/删除
export const operation = (data: { sysShopId: string }, method?: Method) => {
    return request({
        url: `/system/shop/${data.sysShopId}`,
        method: method || 'get', // 删除 delete 获取详情get
        data
    })
}

// 获取所有标签
export const getAllTag = () => {
    return request({
        url: `/system/label/queryAll`,
        method: 'get',
    })
}


// 新增标签
export const addTag = (data: { labelName: string }, method?: Method) => {
    return request({
        url: `/system/label`,
        method: method || 'post',
        data
    })
}
// 删除标签
export const deleteTag = (sysLabelId: string) => {
    return request({
        url: `/system/label/${sysLabelId}`,
        method: 'delete',
    })
}
// 添加门店标签
export const storeTags = (data: any) => {
    return request({
        url: `/system/shop/insertShopLabel`,
        method: 'post',
        data
    })
}

// 获取部门列表
export const getDeptList = () => {
    return request({
        // url: `/system/dept/list`,
        // url: `/system/shop/queryAll`,
        url: `/system/shop/selectSysShopAllIncludeUnvisible`,
        method: 'post',
        data: {}
    })
}
export const getDeptStoreList = () => {
    return request({
        url: `/system/dept/list`,
        method: 'get',
    })
}
// 根据部门获取人员
export const getEmplyeeList = (deptId: string) => {
    return request({
        url: `/system/role/authUser/selectUserByRoleRecruitmenterShopDeptByDeptId/${deptId}`,
        // url: `/system/role/authUser/selectUserByRoleRecruitmenterAndDept/${deptId}`,
        method: 'get',
    })
}

// 门闸机出入明细
export const getMachineList = (data: any) => {
    return request({
        url: `/recruitment/ReceptionFacepad/record/list`,
        method: 'post',
        data
    })
}

/**
 * 送厂培训
 * 
 */

// 获取表格数据
export const factoryTraining = (data: object) => {
    return request({
        url: `/customer/contractInfo/findCustomerUnderContract`,
        method: 'post',
        data
    })
}

//修改培训信息
export const updateTrainingDetail = (data: any) => {
    return request({
        url: `/customer/contractInfo/updateTrainingContent`,
        method: 'post',
        data
    })
}
/**
 * 
 * 求职报名
 */

// /recSignUp/lockSignUp
// 报名锁定状态查询
export const lockStatusSignUp = () => {
    return request({
        url: `/recruitment/recSignUp/lockSignUpStatus`,
        method: 'post'
    })
}
// 报名锁定
export const lockSignUp = () => {
    return request({
        url: `/recruitment/recSignUp/lockSignUp`,
        method: 'post'
    })
}
// 报名锁定信息查询
export const lockInfo = () => {
    return request({
        url: `/recruitment/recSignUp/lockSignUpInfo`,
        method: 'post'
    })
}
// 取消锁定
export const cancleLock = () => {
    return request({
        url: `/recruitment/recSignUp/cancelLockSignUp`,
        method: 'put'
    })
}
// 根据memberId查询报名记录
export const signupRecord = (memberId: string) => {
    return request({
        url: `/recruitment/recSignUp/queryUseFullSignUp/${memberId}`,
        method: 'get'
    })
}

// 根据身份证号查询手机号
export const memberTelephone = (idCardNo: string) => {
    return request({
        url: `/member/memberInfo/${idCardNo}`,
        method: 'get'
    })
}

//检查是否是报名富士康
export const checkIsKfs = (contractId: string) => {
    return request({
        url: `/customer/contractInfo/baseContractInfo/${contractId}`,
        method: 'get'
    })
}

// 获取报名订单列表
export const orderRecruitments = (data: any) => {
    return request({
        url: '/customer/order/recruitments/select',
        method: 'post',
        data
    })
}
// 在招订单
export const orderMemberRecruitments = (data: any) => {
    return request({
        url: '/customer/order/member/recruitments/select',
        method: 'post',
        data
    })
}
// 修改用户信息
export const updateUser = (data: any) => {
    return request({
        url: '/recruitment/recSignUp/getSignUpDetail/save',
        method: 'put',
        data
    })
}

// 查询(当前登入用户)部门下的招聘员角色用户
export const autoUser = () => {
    return request({
        url: '/system/role/authUser/selectUserByRoleRecruitmenterShopDept',
        method: 'get',
    })
}

// 报名列表查询
export const signupTableList = (data: any) => {
    return request({
        url: '/recruitment/myRec/signUpListForWeb',
        method: 'post',
        data
    })
}
// 新规则报名
export const checkParam = (data: any) => {
    return request({
        url: '/recruitment/recSignUp/checkParamForWeb',
        method: 'post',
        data
    })
}
// 刷证报名规则验证
export const checkSignTypeAndFare = (data: any) => {
    return request({
        url: '/recruitment/reimburseFare/checkSignTypeAndFare',
        method: 'post',
        data
    })
}

// 保存报名
export const shopAdd = (data: any) => {
    return request({
        url: '/recruitment/recSignUp/signUpByWeb',
        method: 'post',
        data
    })
}
// 报名锁定确认
export const sureLockSignUp = (data: any) => {
    return request({
        url: '/recruitment/recSignUp/updateGateAuth',
        method: 'post',
        data
    })
}

// 通过身份证号获取出生等数据
export const getCardMsg = (idCardNo: string) => {
    return request({
        url: `/external-api/eApi/idAuth/idCardParsing/${idCardNo}`,
        method: 'get',
    })
}

// 报名详情
export const signUpDetail = (signUpId: string) => {
    return request({
        url: `/recruitment/recSignUp/getSignUpDetail/${signUpId}`,
        method: 'get',
    })
}
// 放弃面试
export const webScanGiveUpInterviewSave = (data: any) => {
    return request({
        url: `/recruitment/recShopRegister/webScanGiveUpInterview/save`,
        method: 'post',
        data
    })
}
// 修改手机号
export const updateTel = (data: any) => {
    return request({
        url: `/recruitment/recSignUp/updateMemberPhone`,
        method: 'put',
        data
    })
}
// 修改备注
export const updateRemark = (data: any) => {
    return request({
        url: `/recruitment/recSignUp/updateRemark`,
        method: 'put',
        data
    })
}
// 修改录入富士康状态
export const updateStatus = (data: any) => {
    return request({
        url: `/recruitment/recSignUp/updateSignedFoxconn`,
        method: 'put',
        data
    })
}
// 更改企业
export const updateCustomer = (data: any) => {
    return request({
        url: `/recruitment/recSignUp/updateCustomer`,
        method: 'put',
        data
    })
}
// 更改历史记录
export const historyDataList = (signUpId: string) => {
    return request({
        url: `/recruitment/signUpChange/historyList/${signUpId}`,
        method: 'get',
    })
}
// 查询修改招聘员
export const getRecruitment = (signUpId: string) => {
    return request({
        url: `/recruitment/recSignUp/getUpdateRecruitment/${signUpId}`,
        method: 'get',
    })
}

// 通知更改招聘员
export const updateRecruitment = (data: any) => {
    return request({
        url: `/recruitment/recSignUp/updateRecruitmentNotice`,
        method: 'post',
        data
    })
}

/**
 * 门市登记
 * @param data 
 * @returns 
 */

// 登记列表查询
export const registerList = (data: any) => {
    return request({
        url: '/recruitment/recShopRegister/registerList',
        method: 'post',
        data
    })
}
// 刷证登记检查
export const paramCheck = (idCardNo: string) => {
    return request({
        url: `/recruitment/recShopRegister/brushCheck/${idCardNo}`,
        method: 'get',
    })
}
// 刷证登记
export const webBrush = (signUpId: string, type: number) => {
    return request({
        url: `/recruitment/recShopRegister/webBrush/${signUpId}/${type}`,
        method: 'get',
    })
}

// 登记 富士康录入状态修改
export const updateSignedFoxconn = (data: any) => {
    return request({
        url: `/recruitment/recSignUp/updateSignedFoxconn`,
        method: 'put',
        data
    })
}
// 确认锁住记录
export const confrimRecord = (data: any) => {
    return request({
        url: `/recruitment/recSignUp/confirmAndLock`,
        method: 'post',
        data
    })
}

// 查询报名信息
export const webBrushSignUpInfo = (idCardNo: string) => {
    return request({
        url: `/recruitment/recShopRegister/webBrushSignUpInfo/${idCardNo}`,
        method: 'get',
    })
}
// 人证比对确认
export const confirmRegistration = (data: any) => {
    return request({
        url: "/recruitment/receptionistFacepadInfo/confirmRegistration/register",
        // url: `/recruitment/receptionistFacepadInfo/confirmRegistration/${idCardNo}`,
        method: 'post',
        data
    })
}

// 更新用户信息
export const updateUserMsg = (data: any) => {
    return request({
        url: "/member/memberInfo/updateMemberInfoByBrush",
        // url: `/recruitment/receptionistFacepadInfo/confirmRegistration/${idCardNo}`,
        method: 'post',
        data
    })
}
// 保存打印
export const printFlagSave = (data: any) => {
    return request({
        url: `/recruitment/recSignUp/printFlagSave`,
        method: 'post',
        data
    })
}
// 打印状态查询
export const printFlagQuery = (data: any) => {
    return request({
        url: `/recruitment/recSignUp/printFlagQuery`,
        method: 'post',
        data
    })
}

/**
 * 刷证上车
 */

// 获取订单列表
export const arrangerScanToBus = () => {
    return request({
        url: '/recruitment/arrangerScanToBus/list',
        method: 'get',
    })
}

// 刷卡上车检查
export const swipeCheck = (data: any) => {
    return request({
        url: '/recruitment/arrangerScanToBus/swipeCheck',
        method: 'post',
        data
    })
}

// 刷卡上车提交
export const swipeCommit = (data: any) => {
    return request({
        url: `/recruitment/arrangerScanToBus/swipeCommit`,
        method: 'post',
        data
    })
}
// 获取选择车辆列表
export const getCarList = (carInfoId: string, carNumber: string) => {
    return request({
        url: `/transport/carInfo/search?carInfoId=${carInfoId}&carNumber=${carNumber}`,
        method: 'get',
    })
}

// 今日车辆列表
export const carListRequest = () => {
    return request({
        url: `/transport/carApply/getTodayBatchList`,
        method: 'get',
    })
}

// 获取车辆订单信息
export const getCarOrderList = (batchId: string) => {
    return request({
        url: `/transport/carApply/getTodayBatchByBatchId?batchId=${batchId}`,
        method: 'get',
    })
}

// 新增车辆
export const carApply = (data: any) => {
    return request({
        url: `/transport/carApply/insertWeb`,
        method: 'post',
        data
    })
}

// 获取车辆详情
export const getCarDetail = (batchId: string) => {
    return request({
        url: `/transport/carApply/getWeb/${batchId}`,
        method: 'get',
    })
}

// 费用是否可编辑
export const carBatchCost = (carInfoId: string, batchId?: string) => {
    return request({
        url: `/transport/carApply/getCarBatchCost?carInfoId=${carInfoId}&batchId=${batchId}`,
        method: 'get',
    })
}

// update车辆信息
export const updateCarMsg = (data: any) => {
    return request({
        url: `/transport/carApply/updateWebNew`,
        method: 'post',
        data
    })
}
// 删除订单
export const deleteOrder = (batchId: string, contractId: string) => {
    return request({
        url: `/transport/carApply/deleteOrder/${batchId}/${contractId}`,
        method: 'delete',
    })
}
// 删除车辆
export const deleteCar = (batchId: string) => {
    return request({
        url: `/transport/carApply/deleteCar/${batchId}`,
        method: 'delete',
    })
}
// 确认发车
export const affirmDepart = (data: any) => {
    return request({
        url: '/recruitment/arrangerScanToBus/affirm',
        method: 'post',
        data
    })
}

// 发车图片
export const carPictureList = () => {
    return request({
        url: '/transport/carPicture/list',
        method: 'post',
    })
}


export const getStandardCost = (data: any) => {
    return request({
        url: `/transport/costConfig/getStandardCost/${data?.toType}/${data?.toId}/${data?.carInfoId}`,
        method: 'get',
    })
}
// 刷证上车列表
export const memberList = (data: any, method?: Method) => {
    return request({
        url: '/recruitment/arrangerScanToBus/memberList',
        method: 'post',
        data
    })
}

// 打印
export const memberListToExport = (data: any) => {
    return request({
        url: '/recruitment/arrangerScanToBus/memberListToExport',
        method: 'post',
        data
    })
}
// 车次列表
export const getBatchListForWeb = (data: any) => {
    return request({
        url: '/transport/carApply/getBatchListForWeb',
        method: 'post',
        data
    })
}
// 修改费用
export const updateCost = (data: any) => {
    return request({
        url: `/transport/carApply/updateCostObj`,
        method: 'post',
        data,
    })
}
// 取消上车登记 
export const cancelToBus = (data: any) => {
    return request({
        url: '/recruitment/arrangerScanToBus/cancelToBus',
        method: 'post',
        data
    })
}

// 确认上车弹框
export const quickToBus = (data: any) => {
    return request({
        url: '/recruitment/arrangerScanToBus/quickToBus',
        method: 'post',
        data
    })
}
// 拒绝上车
export const refuseSignOnAndNoticeRecruiter = (data: any) => {
    return request({
        url: '/transport/carSign/refuseSignOnAndNoticeRecruiter',
        method: 'post',
        data
    })
}
// 新员工无需审批导出
export const exportNewEmployee = (url: string, data: any) => {
    return request({
        url,
        method: 'post',
        data
    })
}

export const permissionExport = (url: string, data: any) => {
    return request({
        url,
        method: 'post',
        data
    })
}
// 导出数据 
export const exportData = async (data: any, url?: string, method?: Method, name?: string, callBack?: Function, isImg?: any) => {
    const token = cookie.get("Authorization");
    await axios({
        method: method || 'post',
        url: baseURL + url,
        responseType: 'blob',
        timeout: 300000,
        data: data,
        headers: {
            'Content-Type': 'application/json;charset=utf-8',
            Authorization: `Bearer ${token}`
        },
    }).then(function (response) {
        if (response.status === 200) {
            let fileName = name || new Date().getTime() + '';
            // 将`blob`对象转化成一个可访问的`url`
            let url = window.URL.createObjectURL(new Blob([response.data]));

            let link = document.createElement('a');
            link.style.display = 'none';
            link.href = url;
            if (isImg === true) {
                link.download = fileName + '.png';
            } else if (isImg === 'doc') {
                link.download = fileName + '.docx';
            } else if (isImg === 'pdf') {
                link.download = fileName + '.pdf';
            } else if (isImg === 'word') {
                link.download = fileName + '.docx';
            } else {
                link.download = fileName + '.xlsx';
            }
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            if (callBack) { callBack(isImg === 'pdf' ? url : false) }
        }
    }).catch(() => {
        if (callBack) { callBack(false) };
        message.info('导出失败');
    });
}

// 上传文档
export const uploadFile = (data: any, url: string, method?: Method) => {
    const token = cookie.get("Authorization");
    return new Promise((resolve) => {
        axios({
            method: method || 'post',
            url: baseURL + url,
            timeout: 120000,
            data: data,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${token}`
            },
        }).then((response) => {
            if (response.status === 200) {
                const { data } = response;
                resolve(data);
            }
        }).catch(() => {
            message.info("上传失败");
        })
    })

}
/**
 * 导入离职员工表
 * @param customerId 
 * @param data 
 * @returns 
 */
export const inportQuitEmployees = (url: string, customerId: string, data: any) => {
    return request({
        url: url,
        method: 'post',
        data,
        headers: { 'Content-Type': 'multipart/form-data;charset=utf-8' }
    })
}


/**
 * @param data 
 * 客资管理
 */

// 来电分配列表
export const getPhoneList = (data: any) => {
    return request({
        url: '/system/incomingcallallocation',
        method: 'post',
        data
    })
}
// 新增来电分配
export const addPhone = (data: any) => {
    return request({
        url: `/system/incomingcallallocation/create`,
        method: 'post',
        data
    })
}
// 查看手机信息详情
export const getPhoneDetail = (id: string) => {
    return request({
        url: `/system/incomingcallallocation/${id}`,
        method: 'get',
    })
}
// 删除来电分配信息
export const deletePhone = (id: string) => {
    return request({
        url: `/system/incomingcallallocation/delete/${id}`,
        method: 'post',
    })
}

// 跟新应用状态
export const updatePhone = (id: string) => {
    return request({
        url: `/system/incomingcallallocation/update/${id}`,
        method: 'post',
    })
}

// 导入客资
export const saveResourceImport = (data: any) => {
    return request({
        url: `/system/resource/import`,
        method: 'post',
        data,
    })
}

// 导出失败客资
export const exportFail = (batchId: string) => {
    return request({
        url: `/system/resource/exportfail/${batchId}`,
        method: 'get',
    })
}

// 客资详情
export const importDetail = (batchId: string) => {
    return request({
        url: `/system/resource/getImportBatchDetail/${batchId}`,
        method: 'post',
    })
}

// 客资列表
export const resourceList = (data: any) => {
    return request({
        url: `/system/resource/page`,
        method: 'post',
        data
    })
}

// 未下发用户列表
export const getListNotIssued = (data: any) => {
    return request({
        url: `/system/resourceAllocation/getListNotIssued`,
        method: 'post',
        data
    })
}
/**
 * 订单管理
 */


// 订单列表
export const orderManagerList = (data: any) => {
    return request({
        url: '/customer/orderPublish/pages',
        method: 'post',
        data
    })
}

// 订单详情
export const orderDetail = (orderId: any) => {
    return request({
        url: `/customer/orderPublish/detail/${orderId}`,
        method: 'get',
    })
}
export const orderDetailNew = (orderId: any) => {
    return request({
        url: `/customer/orderPublish/detail2/${orderId}`,
        method: 'get',
    })
}

// 订单保存
export const orderSave = (data: any) => {
    return request({
        url: '/customer/orderPublish/save',
        method: 'post',
        data
    })
}

// 自定义标签列表
export const customLabel = () => {
    return request({
        url: `/customer/orderUserLabel/userLabels`,
        method: 'get',
    })
}

// 新增订单标签
export const addLabel = (data: any) => {
    return request({
        url: '/customer/orderUserLabel',
        method: 'post',
        data
    })
}

// 新增订单标签
export const deleteLabel = (labelId: string) => {
    return request({
        url: `/customer/orderUserLabel/${labelId}`,
        method: 'delete',
    })
}

// 对接渠道
export const getChannel = (type?: any) => {
    let param = type === 0 || type ? `?dockingChannelType=${type}` : ""
    return request({
        url: `/system/incomingcallallocation/getSysDockingChannel${param}`,
        method: 'get',
    })
}

// 今日在招订单
export const todayRecruit = (data: any) => {
    return request({
        url: `/customer/order/recruitments`,
        method: 'post',
        data
    })
}
// 查询招聘订单分页海报
export const recruitmentsPoster = (data: any) => {
    return request({
        url: `/customer/order/recruitments/poster`,
        method: 'post',
        data
    })
}
// 在招订单详情
export const todayOrderDetail = (orderId: any) => {
    return request({
        url: `/customer/order/detail/${orderId}`,
        method: 'get',
    })
}

export const todayOrderDetailNew = (orderId: any) => {
    return request({
        url: `/customer/order/v2/detail/${orderId}`,
        method: 'get',
    })
}
/**
 * 来电分配
 */

// 获取招聘员
export const recruitmenterList = () => {
    return request({
        url: '/system/role/authUser/selectUserByRoleRecruitmenterOfAllShop',
        method: 'get',
    })
}

// 获取多门店招聘员
export const mutilStoreRecruitmenterList = (data: any) => {
    return request({
        url: '/system/user/queryChildrenInOrganizationWithDeptIdsAndRoles',
        method: 'post',
        data,
    })
}

export const excludeNoShop = (data: any) => {
    return request({
        url: '/system/user/queryChildrenInOrganizationWithDeptIdsAndRoles/excludeNoShop',
        method: 'post',
        data,
    })
}

export const listKs = (data: any) => {
    return request({
        url: '/system/resourceAllocation/listKs',
        data,
        method: 'post',
    })
}
export const channelList = (data: any) => {
    return request({
        url: `/system/incomingcallallocation/getSysDockingChannel?dockingChannelType=${data}`,
        method: 'get',
    })
}
export const ruleInsert = (data: any) => {
    return request({
        url: '/system/resourceAllocation/ruleInsert',
        data,
        method: 'post',
    })
}
export const getConfigRuleSumList = (data: any = '') => {
    return request({
        url: `/system/resourceAllocation/getConfigRuleSumList?fromSourceName=${data}`,
        method: 'get',
    })
}
export const getConfigRuleList = (data: any) => {
    return request({
        url: `/system/resourceAllocation/getConfigRuleList?fromSourceId=${data}`,
        method: 'get',
    })
}
export const deleteUser = (data: any) => {
    return request({
        url: `/system/resourceAllocation/deleteUser/${data}`,
        method: 'DELETE',
    })
}
export const changeEnabled = (data: any) => {
    return request({
        url: `/system/resourceAllocation/changeEnabled/${data}`,
        method: 'get',
    })
}

// 获取招聘员
export const recruitmenterListNew = (channelId: string) => {
    return request({
        url: `/system/resource/configShop/shopConfigList/${channelId}`,
        method: 'get',
    })
}

// 根据角色获取招聘员
export const byRoleRecruitmenter = (roleKey: string) => {
    return request({
        url: `/system/role/authUser/selectUserByRoleOfShopDeptByRoleKey/${roleKey}`,
        method: 'get',
    })
}
// 门市比例配置根据门市筛选客资管理员   招聘员roleKey: recruitmenter
export const byRoleDeptIdRecruitmenter = (deptId: string, roleKey: string = "recruitmenter",) => {
    return request({
        url: `/system/role/authUser/selectUserByRoleKeyAndDeptId/${roleKey}/${deptId}`,
        method: 'get',
    })
}

// 根据角色获取带门市的招聘员
export const selectRecruiterByLoginUserShop = () => {
    return request({
        url: `/system/role/authUser/selectRecruiterByLoginUserShopId`,
        method: 'get',
    })
}

export const listKsExport = (data: any) => {

    exportData(data, 'system/resourceAllocation/listKsExport', 'post', '资源分配快手导出');
}


/**
 * 刷证查询
 * @param data 
 * @returns 
 */

// 刷证查询报名
export const signUpInfo = (data: any) => {
    return request({
        url: '/recruitment/recSignUp/getSignUpInfo',
        method: 'post',
        data
    })
}

// 门市登记打印标签
export const shopRegisterSprintInfo = (data: any) => {
    return request({
        url: '/recruitment/recShopRegister/shopRegisterSprintInfo',
        method: 'post',
        data
    })
}
/**
 * 银行卡换绑
 */
export const getBackCardBingList = (data: any) => {
    return request({
        url: '/recruitment/bankCardBinding/listPage',
        method: 'post',
        data
    })
}
/**
 * 银行卡审核
 */
export const backCardBingExamine = (data: any) => {
    return request({
        url: '/recruitment/bankCardBinding/batchAudit',
        method: 'post',
        data
    })
}
/**
 * 统计未处理
 */
export const countByStatus = () => {
    return request({
        url: '/recruitment/bankCardBinding/countByStatus',
        method: 'get',
        data: {}
    })
}
// 客资管理-微信小程序配置
export const getWechatList = (data: any) => {
    return request({
        url: '/system/resourceAllocation/wechatList',
        method: 'post',
        data
    })
}

// 新增招聘员
export const addRecruiter = (data: any) => {
    return request({
        url: `/system/resourceAllocation/addRecruiter`,
        method: 'post',
        data
    })
}

// 删除招聘员
export const deleteRecruiter = (resourceConfigWechatId: string) => {
    return request({
        url: `/system/resourceAllocation/deleteRecruiter/${resourceConfigWechatId}`,
        method: 'post',
        data: {}
    })
}

// 编辑招聘员
export const updateRecruiter = (resourceConfigRuleId: string, enabled: any) => {
    return request({
        url: `/system/resourceAllocation/updateRecruiter/${resourceConfigRuleId}?enabled=${enabled}`,
        method: 'post',
        data: {}
    })
}

// 招聘员配置列表 - new
export const getRecruiterList = (data: any) => {
    return request({
        url: "/system/resource/recruiterConfig/pageRecruiterList",
        method: 'post',
        data
    })
}

// 新增招聘员 - 新接口
export const addRecruiterNew = (data: any) => {
    return request({
        url: `/system/resource/recruiterConfig/addRecruiter`,
        method: 'post',
        data
    })
}

// 编辑招聘员 - new
export const updateRecruiterNew = (resourceConfigRuleId: string, enabled: any) => {
    return request({
        url: `/system/resource/recruiterConfig/updateRecruiterEnabled/${resourceConfigRuleId}?enabled=${enabled}`,
        method: 'post',
        data: {}
    })
}
// 删除招聘员 - new
export const deleteRecruiterNew = (resourceConfigWechatId: string) => {
    return request({
        url: `/system/resource/recruiterConfig/deleteRecruiter/${resourceConfigWechatId}`,
        method: 'post',
        data: {}
    })
}

// 招聘员渠道配置
export const recruiterConfig = (data: any) => {
    return request({
        url: `/system/resourceAllocation/findchannelConfOfRecruiter`,
        method: 'post',
        data
    })
}

// 招聘员添加渠道
export const recruiterAddChannel = (data: any) => {
    return request({
        url: `/system/resource/recruiterConfig/addSingleRecruiter`,
        method: 'post',
        data
    })
}

// 比例配置列表
export const getShopConfigList = () => {
    return request({
        url: '/system/resourceAllocation/shopConfigList',
        method: 'get',
        data: {}
    })
}
// 新增比例配置
export const addShopConfig = (data: any) => {
    return request({
        url: '/system/resourceAllocation/shopConfig',
        method: 'post',
        data
    })
}

// 比例配置列表-new
export const getShopConfigListNew = (data: any) => {
    return request({
        url: '/system/resource/configShop/page',
        method: 'post',
        data,
    })
}

// 新增比例配置-new
export const addShopConfigNew = (data: any) => {
    return request({
        url: `/system/resource/configShop/saveOrUpdate/${data.channelId}`,
        method: 'post',
        data: data.configList
    })
}

// 比例详情
export const proportionDetail = (channelId: string) => {
    return request({
        url: `/system/resource/configShop/shopConfigList/${channelId}`,
        method: 'get'
    })
}

// 根据渠道获取配置
export const getChannelShopConfigBatch = (data: any) => {
    return request({
        url: `/system/resource/configShop/getChannelShopConfigBatch`,
        method: 'post',
        data,
    })
}

// 批量修改配置
export const saveOrUpdateBatch = (data: any) => {
    return request({
        url: `/system/resource/configShop/saveOrUpdateBatch`,
        method: 'post',
        data,
    })
}

/**
 * 内容管理模块
 */
// 广告位管理
// 新增广告
export const addAdvertising = (data: any) => {
    return request({
        url: '/operation/ad',
        method: 'post',
        data
    })
}
// 修改
export const updateAdvertising = (data: any) => {
    return request({
        url: `/operation/ad/${data.adId}`,
        method: 'post',
        data
    })
}

// 获取推送人数
export const getPushCount = (data: any) => {
    return request({
        url: '/operation/ad/pushCount',
        method: 'post',
        data
    })
}
// 广告列表
export const getAdvertisingList = (type: number) => {
    return request({
        url: `/operation/ad/getList/${type}`,
        method: 'get',
        data: {}
    })
}
// 删除广告
export const delAdvertising = (id: any) => {
    return request({
        url: `/operation/ad/${id}`,
        method: 'delete',
        data: {}
    })
}

// 短剧
export const shortVideoList = (data: any = {}) => {
    return request({
        url: `/operation/shortVideo/list`,
        method: 'post',
        data
    })
}
// 获取广告位详情
export const getAdDetail = (id: string) => {
    return request({
        url: `/operation/ad/${id}`,
        method: 'get'
    })
}

export const getPostAd = (type: number) => {
    return request({
        url: `/operation/ad/getPostAd/${type}`,
        method: 'get'
    })
}

//广告位规则
export const getRuleListForAd = (data: any) => {
    return request({
        url: `/operation/ad/getRuleListForAd`,
        method: 'post',
        data
    })
}
// 批量修改广告位
export const updateRuleBatch = (data: any) => {
    return request({
        url: `/operation/ad/updateRuleBatch`,
        method: 'post',
        data
    })
}

// 历史配置记录
export const getDeleteList = (data: any) => {
    return request({
        url: `/operation/ad/getDeleteList`,
        method: 'post',
        data
    })
}

// 常见问题管理
// 常见问题列表
export const getQuestionList = (data: any) => {
    return request({
        url: '/operation/question/getList',
        method: 'post',
        data
    })
}
// 新增问题
export const addQuestion = (data: any) => {
    return request({
        url: '/operation/question',
        method: 'post',
        data
    })
}
// 编辑问题
export const editQuestion = (data: any, id: any) => {
    return request({
        url: `/operation/question/${id}`,
        method: 'put',
        data
    })
}
// 问题详情
export const questionDetail = (id: any) => {
    return request({
        url: `/operation/question/detailWeb/${id}`,
        method: 'get',
        data: {}
    })
}
// 问题删除
export const delQuestion = (id: any) => {
    return request({
        url: `/operation/question/${id}`,
        method: 'delete',
        data: {}
    })
}

/**
 * 运服管理
 */

// 登录用户可查看的门店列表
export const userStoreList = () => {
    return request({
        url: `/recruitment/arrangerDistribution/shops`,
        method: 'get',
    })
}

export const userSpecialStoreList = () => {
    return request({
        url: `/transport/carApply/getShopListByCurrentUserSpecial`,
        method: 'post',
    })
}

// 车次列表
export const costBatchList = (data: any) => {
    return request({
        url: `/transport/carApply/costBatchList`,
        method: 'post',
        data
    })
}
// 修改车费
export const updateCostByZbwy = (data: any) => {
    return request({
        url: `/transport/carApply/updateCostByZbwy?batchId=${data?.batchId}&cost=${data?.cost}`,
        method: 'post',
        // data
    })
}
// 财务视角车次列表
export const finaceCostBatchList = (data: any) => {
    return request({
        url: `/transport/carSign/getMemberListForCw`,
        method: 'post',
        data
    })
}

// 统计
export const costBatchListStatistics = (data: any) => {
    return request({
        url: `/transport/carApply/costBatchListStatistics`,
        method: 'post',
        data
    })
}

// 添加备注
export const setBatchNote = (data: any) => {
    return request({
        url: `/transport/carApply/setBatchNote`,
        method: 'post',
        data
    })
}

// 车次详情
export const costBatchDetail = (data: any) => {
    return request({
        url: `/transport/carApply/initCarExpense`,
        method: 'post',
        data
    })
}
// 通勤用车  车费审批回显 
export const costBatchSpecialExpense = (data: any) => {
    return request({
        url: `/transport/specialBatch/initSpecialExpense`,
        method: 'post',
        data
    })
}
// 通勤用车 车费审批
export const addSpecialExpense = (data: any) => {
    return request({
        url: `/transport/specialBatch/addSpecialExpense`,
        method: 'post',
        data
    })
}
// 人员列表
export const costPersonList = (data: any) => {
    return request({
        url: `/transport/carSign/getCostBatchDetailList`,
        method: 'post',
        data
    })
}
// 发起车费审批
export const addExpenseAppraval = (data: any) => {
    return request({
        url: `/transport/carExpense`,
        method: 'post',
        data
    })
}

// 车费审批列表
export const expenseList = (data: any) => {
    return request({
        url: `/transport/carExpense/getList`,
        method: 'post',
        data
    })
}

// 车费审批详情
export const carExpenseDetail = (id: string) => {
    return request({
        url: `/transport/carExpense/${id}`,
        method: 'get',
    })
}

// 车费审批详情 - new
export const carExpenseNewDetail = (id: string) => {
    return request({
        url: `/transport/carExpense/new/${id}`,
        method: 'get',
    })
}

// 通勤用车审批详情
export const carExpenGetSpecial = (id: string) => {
    return request({
        url: `/transport/carExpense/getSpecial/${id}`,
        method: 'get',
    })
}
// 详情提交
export const saveDetail = (data: string) => {
    return request({
        url: `/transport/carExpense/approve`,
        method: 'post',
        data
    })
}
/**
 * 通勤用车明细
 */
// 车次列表
export const specialBatchPageList = (data: any) => {
    return request({
        url: `/transport/specialBatch/specialBatchPageList`,
        method: 'post',
        data
    })
}
// 查看通勤用车明细
export const scanToBusMemberList = (data: any) => {
    return request({
        url: `/transport/specialBatch/scanToBusMemberList`,
        method: 'post',
        data
    })
}
// 统计
export const costSpecialBatchListStatistics = (data: any) => {
    return request({
        url: `/transport/specialBatch/costSpecialBatchListStatistics`,
        method: 'post',
        data
    })
}
// 更新备注
export const specialBatchUpdateRemark = (data: any) => {
    return request({
        url: `/transport/specialBatch/updateRemark`,
        method: 'post',
        data
    })
}
/**
 * 报名规则配置
 */

// 列表
export const signRuleList = (data: string) => {
    return request({
        url: `/customer/customerSignRule/signRule/list`,
        method: 'post',
        data
    })
}
// 详情
export const signRuleDetail = (departmentId: string) => {
    return request({
        url: `/customer/customerSignRule/signRule/detail/${departmentId}`,
        method: 'get',
    })
}

// 保存
export const signRuleSave = (data: any) => {
    return request({
        url: `/customer/customerSignRule/signRule/save`,
        method: 'post',
        data
    })
}


// 到期预警用户
export const expireWarningMember = (data: any) => {
    return request({
        url: `/recruitment/newEmployee/expireWarningMember`,
        method: 'post',
        data
    })
}

// 在离职用户
export const exitStatisticsList = (data: any) => {
    return request({
        url: `/recruitment/exitStatisticsReport/exitStatisticsList`,
        method: 'post',
        data
    })
}

/**
 * 开屏广告管理
 */

// 列表
export const launchList = (data: any) => {
    return request({
        url: `/operation/adLaunch/getList`,
        method: 'post',
        data
    })
}
// 新增开屏广告
export const addADLaunch = (data: any) => {
    return request({
        url: `/operation/adLaunch`,
        method: 'post',
        data
    })
}
// 修改
export const updateADLaunch = (data: any) => {
    return request({
        url: `/operation/adLaunch/${data.adLaunchId}`,
        method: 'put',
        data
    })
}
// 详情
export const launchDetail = (id: string) => {
    return request({
        url: `/operation/adLaunch/${id}`,
        method: 'get',
    })
}

/**
 * 门店接待时间配置
 */


// 门店接待配置列表
export const getTimeConfigList = (data: any) => {
    return request({
        url: `/system/ShopArrangeDepartment/getList`,
        method: 'post',
        data
    })
}

export const saveTimeConfig = (data: any) => {
    return request({
        url: `/system/ShopArrangeDepartment/setTime/${data.id}`,
        method: 'post',
        data: data.forms
    })
}

// 是否接待
export const setArrangeFlag = (data: any) => {
    return request({
        url: `/system/ShopArrangeDepartment/setArrangeFlag/${data.id}/${data.arrangeFlag}`,
        method: 'post',
    })
}

/**
 * 资讯管理
 */

// 资讯列表
export const getNewsInfoList = (data: any) => {
    return request({
        url: `/moments/newsInfo/getPage`,
        method: 'post',
        data
    })
}

// 详情
export const getNewsInfoDetail = (data: any) => {
    return request({
        url: `/moments/newsInfo/getOne`,
        method: 'post',
        data
    })
}
// 保存
export const newsInfoSave = (data: any) => {
    return request({
        url: `/moments/newsInfo/save`,
        method: 'post',
        data
    })
}

// 栏目管理
export const newsColumnSave = (data: any) => {
    return request({
        url: `/moments/newsColumn/save`,
        method: 'post',
        data
    })
}

export const getNewsColumnList = (data: any) => {
    return request({
        url: `/moments/newsColumn/getList`,
        method: 'post',
        data
    })
}

export const newsColumnDelete = (data: any) => {
    return request({
        url: `/moments/newsColumn/delete`,
        method: 'post',
        data
    })
}


/***
 * 回收池
 */

// 回收用户列表
export const getListRecyclePool = (data: any) => {
    return request({
        url: `/system/resourceRecyclePool/getListRecyclePool`,
        method: 'post',
        data
    })
}
// 城市
export const getTreeByRecyclePool = (data: any = {}) => {
    return request({
        url: `/system/resourceAllocation/getTreeByRecyclePool`,
        method: 'post',
        data
    })
}

// 回收规则详情
export const recycleRuleDetail = () => {
    return request({
        url: `/system/resourceRecyclePool/getRecycleRule`,
        method: 'get',
    })
}

// 回收规则配置
export const saveRecycleRule = (data: any) => {
    return request({
        url: `/system/resourceRecyclePool/saveRecycleRule`,
        method: 'post',
        data
    })
}

// 自动回收客资
export const recycleAllocation = (data: any) => {
    return request({
        url: `/system/resourceRecyclePool/recycleAllocation`,
        method: 'post',
        data
    })
}

// 用户获取记录列表
export const getListByGain = (data: any) => {
    return request({
        url: `/system/resourceRecyclePool/getListByGain`,
        method: 'post',
        data
    })
}


// 剩余回收量
export const gainResourceLeft = () => {
    return request({
        url: `/system/resourceRecyclePool/gainResourceLeft`,
        method: 'post',
    })
}

// 用户获取动作
export const gainResource = (data: any) => {
    return request({
        url: `/system/resourceRecyclePool/gainResource`,
        method: 'post',
        data,
    })
}

// 自主报名
export const autonomyMemberList = (data: any) => {
    return request({
        url: `/recruitment/recMemberSignUp/selfSignUp/memberList`,
        method: 'post',
        data,
    })
}
// 服务归属记录
export const getServiceChatList = (data: any) => {
    return request({
        url: `/recruitment/recMemberSignUp/selfSignUp/getServiceChatList`,
        method: 'post',
        data,
    })
}

// 获取角色名称为(服务组-专员 营销组-专员 营销组-主管)的人员列表
export const getSysUserSummaryByRoleKeys = () => {
    return request({
        url: `/member/operate/getSysUserSummaryByRoleKeys`,
        method: 'post',
    })
}

//自主报名 - 报名详情
export const autonomySignUpDetail = (signUpId: string) => {
    return request({
        url: `/recruitment/recMemberSignUp/getSignUpDetail/${signUpId}`,
        method: 'get',
    })
}
export const getSignUpDetailBySignUpId = (signUpId: string) => {
    return request({
        url: `/recruitment/recMemberSignUp/getSignUpDetailBySignUpId/${signUpId}`,
        method: 'get',
    })
}
export const getSignUpDetailBySignUpIdNew = (data: any) => {
    return request({
        url: `/recruitment/recMemberSignUp/getSignUpDetailBySignUpIdNew`,
        method: 'post',
        data
    })
}

// 取消报名
export const cancelSignUpForWeb = (data: { id: string }) => {
    return request({
        url: `/recruitment/myRec/cancelSignUpForWeb`,
        method: 'post',
        data,
    })
}

export const updateSignUp = (data: any) => {
    return request({
        url: `/recruitment/recMemberSignUp/updateSignUp`,
        method: 'post',
        data,
    })
}
// 自主报名 - 服务人员列表 
export const autonomySignUpServiceList = () => {
    return request({
        url: `/recruitment/recMemberSignUp/getSignUpDetail/serviceList`,
        method: 'post',
    })
}

// 自主报名 - 修改用户信息
export const autonomyUpdateUser = (data: any) => {
    return request({
        url: '/recruitment/recMemberSignUp/getSignUpDetail/save',
        method: 'put',
        data
    })
}

// 获取售前售后客服
export const getWaitressGroup = (data: any) => {
    return request({
        url: '/kefu-im/serviceGroup/getWaitressGroup',
        method: 'post',
        data
    })
}

// 分配客服  
export const updateAssignedKefu = (data: any) => {
    return request({
        url: '/kefu-im/chatUser/updateAssignedKefu',
        method: 'post',
        data
    })
}

// 跟进记录
export const getFollowUpCodeList = () => {
    return request({
        url: '/recruitment/recruitmentFollowUpPlan/getFollowUpCodeList',
        method: 'get',
    })
}
/**
 * 企微朋友圈
 */

// 朋友圈列表
export const getCircleFriendsList = (data: any) => {
    return request({
        url: `/system/CircleOfFriends/getCircleOfFriendsDetails`,
        method: 'post',
        data,
    })
}

// 新增朋友圈
export const createCircleOfFriends = (data: any) => {
    return request({
        url: '/system/CircleOfFriends',
        method: 'post',
        data
    })
}

// 发表成员
export const queryDeptWithSysUsers = () => {
    return request({
        url: '/system/CircleOfFriends/getDeptUsersInShops',
        method: 'get',
    })
}
// 发表成员
export const getDeptUsers = () => {
    return request({
        url: '/system/user/getDeptUsers',
        method: 'get',
    })
}

// 更新数据
export const updateByTasks = (data: any) => {
    return request({
        url: '/system/CircleOfFriends/updateSendResultByTaskIds',
        method: 'post',
        data
    })
}

// 朋友圈详情
export const circleOfFriendsDetail = (taskId: string) => {
    return request({
        url: `/system/CircleOfFriends/${taskId}`,
        method: 'get',
    })
}

// 朋友圈成员执行情况查询
export const getMomentTask = (data: any) => {
    return request({
        url: `/system/CircleOfFriends/getMomentTask`,
        method: 'post',
        data
    })
}
// 取消发送
export const cancelMomentTask = (data: any) => {
    return request({
        url: `/system/CircleOfFriends/deleteCircleOfFriendsByTaskId?taskId=${data?.taskId}`,
        method: 'post',
        data
    })
}

// 提醒发送
export const messagePush = (data: any) => {
    return request({
        url: `/system/CircleOfFriends/messagePush`,
        method: 'post',
        data
    })
}

/**
 * 门闸机白名单
 */

export const getWhiteList = (data: any) => {
    return request({
        url: `/recruitment/receptionistAccessWhite/getPage`,
        method: 'post',
        data
    })
}

export const whiteRowDelete = (data: any) => {
    return request({
        url: `/recruitment/receptionistAccessWhite/delete`,
        method: 'post',
        data
    })
}

export const whiteUpdateStatus = (data: any) => {
    return request({
        url: `/recruitment/receptionistAccessWhite/updateStatus`,
        method: 'post',
        data
    })
}

export const whiteSave = (data: any) => {
    return request({
        url: `/recruitment/receptionistAccessWhite/save`,
        method: 'post',
        data
    })
}
// 获取所有成员
export const getAllUser = () => {
    return request({
        url: `/system/user/findUserAllOnlyUserIdName`,
        method: 'get',
    })
}
// 获取所有成员带有部门名称
export const getAllUserDept = () => {
    return request({
        url: `/system/user/getAllUserAndShop2`,
        method: 'post',
    })
}
// 在招订单
export const getOrderList = (data: any) => {
    return request({
        url: `/customer/order/kfRecruitments`,
        method: 'post',
        data,
    })
}

// 订单管理 - 送厂培训
export const trainingList = (data: any) => {
    return request({
        url: '/customer/order/recruitments/page',
        method: 'post',
        data
    })
}
//详情
export const getPostInfo = (orderId: string) => {
    return request({
        url: `/customer/order/getPostInfo/${orderId}`,
        method: 'get',
    })
}
export const getPostInfoNew = (orderId: string) => {
    return request({
        url: `/customer/order/getPostInfo2/${orderId}`,
        method: 'get',
    })
}

// 资料补充
export const savePostInfo = (data: any) => {
    return request({
        url: '/customer/order/savePostInfo',
        method: 'post',
        data
    })
}

// 日薪管理列表
export const dailySalaryDetailsList = (data: any) => {
    return request({
        url: '/member/dailySalaryManage/dailySalaryDetails',
        method: 'post',
        data
    })
}
// 预支额度
export const dailySalarySumAmount = (data: any) => {
    return request({
        url: '/member/dailySalaryManage/dailySalarySumAmount',
        method: 'post',
        data
    })
}
// 发起日薪保存
export const saveDailySalaryDetails = (data: any) => {
    return request({
        url: '/member/dailySalaryManage/saveDailySalaryDetails',
        method: 'post',
        data
    })
}

/**
 * 招聘管理 - 招聘报表
 */

//部门
export const treeSelectForShop = () => {
    return request({
        url: '/system/dept/treeSelectForShop',
        method: 'get',
    })
}
export const treeSelectList = () => {
    return request({
        url: '/system/dept/treeSelectList',
        method: 'get',
    })
}

// 组织架构
export const getDeptRecruitmentInShops = () => {
    return request({
        url: '/system/user/getDeptRecruitmentInShops',
        method: 'get',
    })
}

// 新老用户核心转化节点对比
export const signUpReport = (data: any) => {
    return request({
        url: '/recruitment/memberTypeReport/signUpReport',
        method: 'post',
        data
    })
}

// 用户入职次数分布
export const inductionCountReport = (data: any) => {
    return request({
        url: '/recruitment/memberTypeReport/inductionCountReport',
        method: 'post',
        data
    })
}

// 报名用户年龄分布
export const memberAgeReport = (data: any) => {
    return request({
        url: '/recruitment/memberTypeReport/memberAgeReport',
        method: 'post',
        data
    })
}

// 报名用户地域分布
export const memberAddressReport = (data: any) => {
    return request({
        url: '/recruitment/memberTypeReport/memberAddressReport',
        method: 'post',
        data
    })
}

// 新用户来源渠道
export const newMemberFromSource = (data: any) => {
    return request({
        url: '/recruitment/memberTypeReport/newMemberFromSource',
        method: 'post',
        data
    })
}
// 报名用户学历分布
export const memberEducationReport = (data: any) => {
    return request({
        url: '/recruitment/memberTypeReport/memberEducationReport',
        method: 'post',
        data
    })
}
// 新用户报名趋势
export const signUpTrend = (data: any) => {
    return request({
        url: '/recruitment/memberTypeReport/signUpTrend',
        method: 'post',
        data
    })
}

// 在职流失分析
export const selectOnJobLoseReport = (data: any) => {
    return request({
        url: '/recruitment/onJobLoseReport/selectOnJobLoseReportList',
        method: 'post',
        data
    })
}

// 财务管理 - 数据合成 -- 列表
export const getFinanceDataSynthesisRecord = (data: any) => {
    return request({
        url: '/recruitment/financeDataSynthesis/getFinanceDataSynthesisRecord',
        method: 'post',
        data
    })
}
// 财务管理 - 数据合成 -- 成功人员
export const getIncomeStatement = (data: any) => {
    return request({
        url: '/recruitment/financeDataSynthesis/getIncomeStatement',
        method: 'post',
        data
    })
}

// 财务管理 - 数据合成 -- 失败人员
export const getMatchingFailures = (data: any) => {
    return request({
        url: '/recruitment/financeDataSynthesis/getMatchingFailures',
        method: 'post',
        data
    })
}
// 结算表配败人员
export const getStatementMatchingFailures = (data: any) => {
    return request({
        url: '/recruitment/financeDataSynthesis/getStatementMatchingFailures',
        method: 'post',
        data
    })
}

export const deleteFinanceData = (data: any) => {
    return request({
        url: '/recruitment/financeDataSynthesis/deleteFinanceData',
        method: 'post',
        data
    })
}

// 财务管理 - 数据合成 -- 匹配成功和匹配失败人员总数查询
export const getSuccessfulFailuresCount = (data: any) => {
    return request({
        url: '/recruitment/financeDataSynthesis/getSuccessfulFailuresCount',
        method: 'post',
        data
    })
}

/**
 * 服务评价分析
 */

// 服务人员列表
export const getUserStatPage = (data: any) => {
    return request({
        url: `/system/userEvaluation/getUserStatPage`,
        method: 'post',
        data
    })
}

// 服务评价
export const userEvaluationDetail = (data: any) => {
    return request({
        url: `/system/userEvaluation/detail/web`,
        method: 'post',
        data
    })
}

export const userEvaluationEdit = (data: any) => {
    return request({
        url: `/system/userEvaluation/edit`,
        method: 'post',
        data
    })
}


//详情
export const getUserDetailPage = (data: any) => {
    return request({
        url: `/system/userEvaluation/getUserDetailPage`,
        method: 'post',
        data
    })
}
// 服务数据报表
export const getStatPage = (data: any) => {
    return request({
        url: `/system/userEvaluation/getStatPage`,
        method: 'post',
        data
    })
}
// 服务数据报表-新
export const getEvaluatePage = (data: any) => {
    return request({
        url: `/system/userEvaluation/getEvaluatePage`,
        method: 'post',
        data
    })
}

/**
 * 服务评价管理
 */

// 列表
export const evaluationListPage = (data: any) => {
    return request({
        url: `/system/evaluation/manager/listPage`,
        method: 'post',
        data
    })
}
// 删除
export const removeEvaluationManagerType = (data: any) => {
    return request({
        url: `/system/evaluation/manager/removeEvaluationManagerType`,
        method: 'post',
        data
    })
}
// 启用按钮
export const updateEvaluationStatus = (data: any) => {
    return request({
        url: `/system/evaluation/manager/updateEvaluationStatus`,
        method: 'post',
        data
    })
}

// 详情
export const getEvaluationManagerType = (data: any) => {
    return request({
        url: `/system/evaluation/manager/getEvaluationManagerType`,
        method: 'post',
        data
    })
}
// 新增
export const saveEvaluationManagerType = (data: any) => {
    return request({
        url: `/system/evaluation/manager/saveEvaluationManagerType`,
        method: 'post',
        data
    })
}
// 编辑
export const updateEvaluationManagerType = (data: any) => {
    return request({
        url: `/system/evaluation/manager/updateEvaluationManagerType`,
        method: 'post',
        data
    })
}

// 评价关键词设置
export const keywordList = () => {
    return request({
        url: `/system/userEvaluation/keyword/list/web`,
        method: 'post',
    })
}

// 增加
export const keywordAdd = (data: any) => {
    return request({
        url: `/system/userEvaluation/keyword/add`,
        method: 'post',
        data,
    })
}

// 修改
export const keywordStatus = (data: any) => {
    return request({
        url: `/system/userEvaluation/keyword/status`,
        method: 'post',
        data,
    })
}


// App评价
export const configWeb = () => {
    return request({
        url: `/system/appEvaluation/configWeb`,
        method: 'get',
    })
}

export const configSave = (data: any) => {
    return request({
        url: `/system/appEvaluation/config/save`,
        method: 'post',
        data
    })
}
// 数据统计
export const dataStatistics = (data: any) => {
    return request({
        url: `/system/appEvaluation/data`,
        method: 'post',
        data
    })
}

/**
 * 门店成本管理
 * @param data 
 * @returns 
 */

export const getShopCostList = (data: any) => {
    return request({
        url: `/transport/shopCost/getList`,
        method: 'post',
        data
    })
}

export const shopCostSave = (data: any) => {
    return request({
        url: `/transport/shopCost/save`,
        method: 'post',
        data
    })
}

// 门店成本分析
export const shopCostReport = (data: any) => {
    return request({
        url: `/recruitment/shopCostReport/shopCostReport`,
        method: 'post',
        data
    })
}

// 门店年度均值列表
export const shopTargetList = (data: any) => {
    return request({
        url: `/transport/shopTarget/getList`,
        method: 'post',
        data
    })
}

export const shopTargetSave = (data: any) => {
    return request({
        url: `/transport/shopTarget/save`,
        method: 'post',
        data
    })
}

// 招聘绩效分析
export const recruitmentPerformanceReport = (data: any) => {
    return request({
        url: `/recruitment/recruitmentPerformanceReport/recruitmentPerformanceReportTree`,
        method: 'post',
        data
    })
}
// 企业视角
export const recruitmentPerformanceReportByDept = (data: any) => {
    return request({
        url: `/recruitment/recruitmentPerformanceReport/recruitmentPerformanceReportByDept`,
        method: 'post',
        data
    })
}

// 报销车费管理-应用范围配置查询
export const processLimitQuery = () => {
    return request({
        url: `/recruitment/reimburseFare/processLimit/query`,
        method: 'post',
        data: {}
    })
}

// 报销车费管理-应用范围配置保存
export const processLimitSave = (limitAmount: string | number) => {
    return request({
        url: `/recruitment/reimburseFare/processLimit/save`,
        method: 'post',
        data: { limitAmount }
    })
}

// 信息标记统计列表
export const markList = (data: any) => {
    return request({
        url: `/system/channelMarkReport/tree`,
        method: 'post',
        data
    })
}

// 企微客资统计 - 招聘视角
export const getResourceAllocationStatisticsDataListTree = (data: any) => {
    return request({
        url: `/system/resourceAllocation/getResourceAllocationStatisticsDataListTree`,
        method: 'post',
        data
    })
}

/**
 * 企微客资统计 - 用户视角
 * @param data 
 * @returns 
 */

// 核心指标  
export const coreData = (data: any) => {
    return request({
        url: `/operation/wecomMemberViewReport/coreData`,
        method: 'post',
        data
    })
}

// 加微招聘员分布   
export const recruitmentAddReport = (data: any) => {
    return request({
        url: `/operation/wecomMemberViewReport/recruitmentAddReport`,
        method: 'post',
        data
    })
}
// 门店添加
export const shopAddCnt = (data: any) => {
    return request({
        url: `/operation/wecomMemberViewReport/shopAddCnt`,
        method: 'post',
        data
    })
}

// 连续加微门店排行
export const continuousAddRank = (data: any) => {
    return request({
        url: `/operation/wecomMemberViewReport/continuousAddRank`,
        method: 'post',
        data
    })
}

// 报名转化分析
export const transformData = (data: any) => {
    return request({
        url: `/operation/wecomMemberViewReport/transformData`,
        method: 'post',
        data
    })
}

// 邀请报名次数及转化效率
export const inviteCntReport = (data: any) => {
    return request({
        url: `/operation/wecomMemberViewReport/inviteCntReport`,
        method: 'post',
        data
    })
}

// 人均邀请数门店排行 
export const inviteAvgRankReport = (data: any) => {
    return request({
        url: `/operation/wecomMemberViewReport/inviteAvgRankReport`,
        method: 'post',
        data
    })
}

/**
 * 门店类别计提
 */

export const categoryList = (data: any = {}) => {
    return request({
        url: `/system/sysShopCategoryProvision/list`,
        method: 'post',
        data
    })
}

// 修改
export const categoryUpdate = (data: any) => {
    return request({
        url: `/system/sysShopCategoryProvision/update`,
        method: 'post',
        data
    })
}

/**
 * 安置转化分析表
 */

// 安置转化分析
export const getAzConversionVO = (data: any) => {
    return request({
        url: `/transport/carSign/getAzConversionVO`,
        method: 'post',
        data
    })
}

// 车费统计分析
export const getCarCostStatisticsBaseVO = (data: any) => {
    return request({
        url: `/transport/carSign/getCarCostStatisticsBaseVO`,
        method: 'post',
        data
    })
}
// 车费类型分析
export const getCostTypeAnalysisVO = (data: any) => {
    return request({
        url: `/transport/carSign/getCostTypeAnalysisVO`,
        method: 'post',
        data
    })
}


/**
 * 门店经营分析
 */
export const shopOperateReport = (data: any) => {
    return request({
        url: `/transport/shopTarget/shopOperateReport`,
        method: 'post',
        data
    })
}

// 门店周在职数据  
export const shoOnJobWeekReport = (data: any) => {
    return request({
        url: `/transport/shopTarget/shoOnJobWeekReport`,
        method: 'post',
        data
    })
}

// 门店月利润数据
export const shoOnJobMonthReport = (data: any) => {
    return request({
        url: `/transport/shopTarget/shoOnJobMonthReport`,
        method: 'post',
        data
    })
}


/**
 * 活动异常预警
 */

/**
 * 异常预警记录
 */

export const getActivityWarningList = (data: any) => {
    return request({
        url: `/operation/activityWarningRuleRecord/getList`,
        method: 'post',
        data
    })
}


export const getActivityWarningDetail = (data: any) => {
    return request({
        url: `/operation/activityWarningRuleRecord/get`,
        method: 'post',
        data
    })
}
/**
 * 预警规则配置
 */

export const getConfig = () => {
    return request({
        url: `/operation/activityWarningRule/get`,
        method: 'post',
    })
}

//  保存配置
export const saveConfig = (data: any) => {
    return request({
        url: `/operation/activityWarningRule/save`,
        method: 'post',
        data
    })
}

/**
 * 活动黑名单
 */

// 列表
export const getList = (data: any) => {
    return request({
        url: `/operation/activityBlacklist/getList`,
        method: 'post',
        data
    })
}

// 删除黑名单
export const deleteBlackList = (data: any) => {
    return request({
        url: `/operation/activityBlacklist/delete`,
        method: 'post',
        data
    })
}

// 黑名单详情
export const getDetailById = (data: any) => {
    return request({
        url: `/operation/activityBlacklist/get`,
        method: 'post',
        data
    })
}
// 新增黑名单
export const insertBlackList = (data: any) => {
    return request({
        url: `/operation/activityBlacklist/insert`,
        method: 'post',
        data
    })
}

// 招聘任务-任务列表
export const recruitmentTaskList = (data: any) => {
    return request({
        url: `/system/recruitmentTask/taskList`,
        method: 'post',
        data
    })
}
// 招聘任务-任务列表
export const recruitmentTaskListHead = (data: any) => {
    return request({
        url: `/system/recruitmentTask/taskListHead`,
        method: 'post',
        data
    })
}
// 招聘任务-新建任务
export const saveOrUpdateTask = (data: any) => {
    return request({
        url: `/system/recruitmentTask/saveOrUpdateTask`,
        method: 'post',
        data
    })
}
// 招聘任务-任务详情
export const recruitmentTaskDetail = (id: string) => {
    return request({
        url: `/system/recruitmentTask/taskDetail`,
        method: 'post',
        data: { id }
    })
}

// 终止任务
export const taskOver = (data: any) => {
    return request({
        url: `/system/recruitmentTask/taskOver`,
        method: 'post',
        data
    })
}

// 招聘任务-查询所有区域及区域内招聘员人数
export const getAreasAndRecruitment = () => {
    return request({
        url: `/system/recruitmentTask/areasAndRecruitment`,
        method: 'post',
        data: {}
    })
}

/**
 * 常规招聘任务
 */

export const routineTaskList = (data: any) => {
    return request({
        url: `/system/recruitmentRoutineTask/list`,
        method: 'post',
        data
    })
}

// 统计
export const listHead = (data: any) => {
    return request({
        url: `/system/recruitmentRoutineTask/listHead`,
        method: 'post',
        data
    })
}
// 获取编辑数据
export const routineTaskDetail = (data: any) => {
    return request({
        url: `/system/recruitmentRoutineTask/detail`,
        method: 'post',
        data
    })
}

export const routineSaveOrUpdateTask = (data: any) => {
    return request({
        url: `/system/recruitmentRoutineTask/saveOrUpdateTask`,
        method: 'post',
        data
    })
}

export const routineTaskDetailNew = (data: any) => {
    return request({
        url: `/system/recruitmentRoutineTask/detailV2`,
        method: 'post',
        data
    })
}

export const routineSaveOrUpdateTaskNew = (data: any) => {
    return request({
        url: `/system/recruitmentRoutineTask/saveOrUpdateTaskV2`,
        method: 'post',
        data
    })
}

/**
 * 招聘任务统计
 */

export const customerView = (data: any) => {
    return request({
        url: `/system/recTaskStat/customerView`,
        method: 'post',
        data
    })
}

export const areaView = (data: any) => {
    return request({
        url: `/system/recTaskStat/areaView`,
        method: 'post',
        data
    })
}

export const shopView = (data: any) => {
    return request({
        url: `/system/recTaskStat/shopView`,
        method: 'post',
        data
    })
}

export const yearView = (data: any) => {
    return request({
        url: `/system/recTaskStat/yearView`,
        method: 'post',
        data
    })
}

/**
 * 招聘-自主报名-运营用户
 */

// 获取城市接口
export const getRegionAll = () => {
    return request({
        url: `/system/region/getRegionAll`,
        method: 'get',
    })
}

// 页签数据统计
export const selectTotal = () => {
    return request({
        url: `/operation/appMemberOperation/selectTotal`,
        method: 'post',
    })
}

// 全部用户
export const allMemberList = (data: any) => {
    return request({
        url: `/operation/appMemberOperation/allMemberList`,
        method: 'post',
        data
    })
}

// IM群发
export const batchSendIM = (data: any) => {
    return request({
        url: `/operation/appMemberOperation/batchSendIM`,
        method: 'post',
        data
    })
}

// 群发记录列表
export const selectImRecordPage = (data: any) => {
    return request({
        url: `/operation/appMemberOperation/selectImRecordPage`,
        method: 'post',
        data
    })
}

// 待转化用户
export const toTransformMemberList = (data: any) => {
    return request({
        url: `/operation/appMemberOperation/toTransformMemberList`,
        method: 'post',
        data
    })
}

// 待转化用户 - 分配服务专员
export const getServerList = () => {
    return request({
        url: `/operation/appMemberOperation/getServerList`,
        method: 'post',
    })
}
export const listGroupUser = () => {
    return request({
        url: `/kefu-im/serviceGroup/listGroupUser`,
        method: 'get',
    })
}
// 服务专员配置保存
export const memberDispatch = (data: any) => {
    return request({
        url: `/operation/appMemberOperation/memberDispatch`,
        method: 'post',
        data
    })
}

// 到期用户
export const expireMemberList = (data: any) => {
    return request({
        url: `/operation/appMemberOperation/expireMemberList`,
        method: 'post',
        data
    })
}
// 接待统计
export const receptionReport = (data: any) => {
    return request({
        url: `/operation/appMemberOperation/receptionReport`,
        method: 'post',
        data
    })
}

/**
 * 服务监控记录
 * 
 *  */

// 客服用户各状态人数统计
export const getStatusCountSummary = () => {
    return request({
        url: `/kefu-im/monitor/getStatusCountSummary`,
        method: 'get',
    })
}

export const getKefuUserPage = (data: any) => {
    return request({
        url: `/kefu-im/monitor/getKefuUserPage`,
        method: 'post',
        data,
    })
}

export const getKefuStatusRecords = (data: any) => {
    return request({
        url: `/kefu-im/monitor/getKefuStatusRecords`,
        method: 'post',
        data,
    })
}

/**
 * 车费配置
 * @param data 
 * @returns 
 */

// 车费审批-车费配置 
export const getCostConfigList = (data: any) => {
    return request({
        url: `/transport/costConfig/getList`,
        method: 'post',
        data
    })
}

// 创建线路
export const costConfig = (data: any) => {
    return request({
        url: `/transport/costConfig`,
        method: 'post',
        data
    })
}

// 更新线路
export const updateCostConfig = (data: any) => {
    return request({
        url: `/transport/costConfig`,
        method: 'put',
        data
    })
}
// 获取线路详情 
export const getCostConfigDetail = (costConfigId: string) => {
    return request({
        url: `/transport/costConfig/${costConfigId}`,
        method: 'get',
    })
}

export const getDistance = (data: { toType: number, toId: string }) => {
    return request({
        url: `/transport/costConfig/getDistance/${data.toType}/${data.toId}`,
        method: 'get',
    })
}
// 审批
export const saveApproval = (data: any) => {
    return request({
        url: `/transport/costConfig/approval`,
        method: 'post',
        data
    })
}

/**
 * 招聘管理 - 技工招聘
 */

/**
 * 技工人才库
 * @param data 
 * @returns 
 */
export const mechanicList = (data: any) => {
    return request({
        url: `/member/mechanic/list`,
        method: 'post',
        data
    })
}
// 检测用户是否已入库
export const mechanicCheck = (data: any) => {
    return request({
        url: `/member/mechanic/check`,
        method: 'post',
        data
    })
}
// 添加用户
export const mechanicSave = (data: any) => {
    return request({
        url: `/member/mechanic/save`,
        method: 'post',
        data
    })
}
// 详情 
export const mechanicDetail = (data: any) => {
    return request({
        url: `/member/mechanic/detail`,
        method: 'post',
        data
    })
}

// 简历查看
export const mechanicResumeDetail = (data: any) => {
    return request({
        url: `/member/mechanic/resumeDetail`,
        method: 'post',
        data
    })
}

// 添加简历
export const resumeAdd = (data: any) => {
    return request({
        url: `/member/mechanic/resumeAdd`,
        method: 'post',
        data
    })
}

/**
 * 报名记录
 */

export const mechanicSignUpList = (data: any) => {
    return request({
        url: `/recruitment/mechanicSignUp/list`,
        method: 'post',
        data
    })
}

// 新增报名记录
export const mechanicSignUpSave = (data: any) => {
    return request({
        url: `/recruitment/mechanicSignUp/save`,
        method: 'post',
        data
    })
}


// 离职登记
export const mechanicSignUpQuit = (data: any) => {
    return request({
        url: `/recruitment/mechanicSignUp/quitRegister`,
        method: 'post',
        data
    })
}


// 入职登记
export const mechanicSignUpInduction = (data: any) => {
    return request({
        url: `/recruitment/mechanicSignUp/inductionRegister`,
        method: 'post',
        data
    })
}


/**
 * 在职管理
 */

export const inductionList = (data: any) => {
    return request({
        url: `/recruitment/mechanicSignUp/induction`,
        method: 'post',
        data
    })
}


// 用户画像 ------start 
// 地域分布
export const memberProfileSelectAddressReport = (data: any) => {
    return request({
        url: `/member-datahub/memberProfile/selectAddressReport`,
        method: 'post',
        data
    })
}
// 用户性别
export const memberProfileSelectAgeAndGenderReport = (data: any) => {
    return request({
        url: `/member-datahub/memberProfile/selectAgeAndGenderReport`,
        method: 'post',
        data
    })
}
// 机型
export const memberProfileSelectMemberPhoneModelReport = (data: any) => {
    return request({
        url: `/member-datahub/memberProfile/selectMemberPhoneModelReport`,
        method: 'post',
        data
    })
}
// 操作系统
export const memberProfileSelectMemberPhoneOsReport = (data: any) => {
    return request({
        url: `/member-datahub/memberProfile/selectMemberPhoneOsReport`,
        method: 'post',
        data
    })
}
// 用户地域分析-根据省查市数据
export const memberProfileSelectCityAddressReport = (data: any) => {
    return request({
        url: `/member-datahub/memberProfile/selectCityAddressReport`,
        method: 'post',
        data
    })
}
// 数据统计-企业统计
export const recruitmentRecSignUpgetSignUpStat = (data: any) => {
    return request({
        url: `/recruitment/recSignUp/getSignUpStat`,
        method: 'post',
        data
    })
}

// 数据统计-在离职统计
export const recruitmentNewListStatementByDept = (data: any) => {
    return request({
        url: `/recruitment/exitStatisticsReportNew/listStatementByDept`,
        method: 'post',
        data
    })
}
// 数据统计-在离职统计-客户区域配置
export const getAreaConfigList = () => {
    return request({
        url: `/recruitment/serviceAreaConfigController/getAreaConfigList`,
        method: 'post',
        data: {}
    })
}
// 数据统计-在离职统计-客户下拉
export const complaintUserListData = () => {
    return request({
        url: `/customer/department/complaintUserListData`,
        method: 'post',
        data: []
    })
}

/**
 * 录取分析统计
 */

// 企业视角
export const admissionReportByDept = (data: any) => {
    return request({
        url: `/recruitment/admissionReport/admissionReportByDept`,
        method: 'post',
        data,
    })
}

// 门店视角
export const admissionReportTree = (data: any) => {
    return request({
        url: `/recruitment/admissionReport/admissionReportTree`,
        method: 'post',
        data,
    })
}


/**
 * 短信配置
 */
export const smsQueryList = (data: any) => {
    return request({
        url: `/member/smsTemplate/queryList`,
        method: 'post',
        data,
    })
}

// 新增编辑短信
export const marketSmsTemplate = (data: any) => {
    return request({
        url: `/member/smsTemplate/marketSmsTemplate`,
        method: 'post',
        data,
    })
}

// 查询
export const querySmsTemplateById = (data: any) => {
    return request({
        url: `/member/smsTemplate/querySmsTemplateById`,
        method: 'post',
        data,
    })
}

// 删除
export const marketSmsTemplateDelete = (data: any) => {
    return request({
        url: `/member/smsTemplate/delMarketSmsTemplate`,
        method: 'post',
        data,
    })
}

/**
 * 搜索热词配置
 *
 */
// 查询
export const hotWordsListPage = (data: any) => {
    return request({
        url: `/system/hotWords/listPage`,
        method: 'post',
        data,
    })
}

// 新增/修改
export const hotWordsSave = (data: any) => {
    return request({
        url: `/system/hotWords/save`,
        method: 'post',
        data,
    })
}

// 下架
export const hotWordsUpdateStatus = (data: any) => {
    return request({
        url: `/system/hotWords/updateStatus`,
        method: 'post',
        data,
    })
}

// 根据登录用户获取对应数据权限拥有的客户部门信息列表
export const getCustomerDepartment = (data: any) => {
    return request({
        url: `/recruitment/largeScreenController/getCustomerDepartment`,
        method: 'post',
        data,
    })
}
// 据登录用户对应数据权限及驻场客服名称关键字查询驻厂客服及驻厂管理员详细配置列表
export const getCustomerServiceInfoList = (data: any) => {
    return request({
        url: `/recruitment/largeScreenController/getCustomerServiceInfoList`,
        method: 'post',
        data,
    })
}
// 获取各省份客服监控大屏地图提示信息列表
export const getMonitoringLargeScreenMapTips = () => {
    return request({
        url: `/recruitment/largeScreenController/getMonitoringLargeScreenMapTips`,
        method: 'post',
        data: {},
    })
}
// 根据userid列表查找客服人员地理位置经纬度信息列表
export const getGeographicLocationListByUserIds = (data: any) => {
    return request({
        url: `/recruitment/largeScreenController/getGeographicLocationListByUserIds`,
        method: 'post',
        data: {},
    })
}

/**
 * seo配置
 */

export const getSettingList = (data: any) => {
    return request({
        url: `/moments/seo/getSettingList`,
        method: 'post',
        data,
    })
}

export const saveSetting = (data: any) => {
    return request({
        url: `/moments/seo/saveSetting`,
        method: 'post',
        data,
    })
}

// 荐岗信息
export const selectRecommendPostInfo = (data: any) => {
    return request({
        url: `/operation/postMatch/selectRecommendPostInfo`,
        method: 'post',
        data,
    })
}

/**
 * 首页公告
 */

// 公告列表
export const getHomeNoticeList = (data: any) => {
    return request({
        url: `/operation-managment/homeNotice/getHomeNoticeList`,
        method: 'post',
        data,
    })
}

// 公告图标
export const getNoticeIconList = () => {
    return request({
        url: `/operation-managment/homeNotice/getNoticeIconList`,
        method: 'post',
    })
}
// 新增公告
export const saveHomeNotice = (data: any) => {
    return request({
        url: `/operation-managment/homeNotice/saveHomeNotice`,
        method: 'post',
        data,
    })
}

//修改
export const updateHomeNotice = (data: any) => {
    return request({
        url: `/operation-managment/homeNotice/updateHomeNotice`,
        method: 'post',
        data,
    })
}

// 公告详情
export const getNotice = (data: any) => {
    return request({
        url: `/operation-managment/homeNotice/getNotice`,
        method: 'post',
        data,
    })
}

// 删除公告 
export const deleteHomeNotice = (data: any) => {
    return request({
        url: `/operation-managment/homeNotice/deleteHomeNoticeByNoticeId`,
        method: 'post',
        data,
    })
}

/**
 * 客资识别
 */

// 列表
export const phoneIdentifyList = (data: any) => {
    return request({
        url: `/operation/phoneIdentify/list`,
        method: 'post',
        data,
    })
}


/**
 * 华为派遣
 */

// 简历列表
export const recruitmentResumeSubmitList = (data: any) => {
    return request({
        url: `/recruitment/recruitmentResumeSubmit/getPage`,
        method: 'post',
        data,
    })
}

// 简历详情
export const recruitmentResumeSubmitDetail = (data: any) => {
    return request({
        url: `/recruitment/recruitmentResumeSubmit/getDetail`,
        method: 'post',
        data,
    })
}

// 简历审核
export const recruitmentResumeSubmitAudit = (data: any) => {
    return request({
        url: `/recruitment/recruitmentResumeSubmit/audit`,
        method: 'post',
        data,
    })
}


// 面试审核
export const interviewAudit = (data: any) => {
    return request({
        url: `/recruitment/recruitmentResumeSubmit/interviewAudit`,
        method: 'post',
        data,
    })
}

// 面试通知消息
export const interviewNotice = (data: any) => {
    return request({
        url: `/recruitment/recruitmentResumeSubmit/interviewNotice`,
        method: 'post',
        data,
    })
}

//心理测评通知  
export const psychometricsNotice = (data: any) => {
    return request({
        url: `/recruitment/recruitmentResumeSubmit/psychometricsNotice`,
        method: 'post',
        data,
    })
}




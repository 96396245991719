/**
 * @ Author: caohenghui
 * @ Create Time: 2023-06-09 10:10:25
 * @ Modified by: Your name
 * @ Modified time: 2025-02-15 09:57:53
 * @ Description: 基础的初始化数据
 */

import { regionLimitOptions } from "pages/businessManager/orderPlan/data"

export const templateOne = {
    posterName: '',
    posterTime: '',
    sysPosterFactoryFormList: [],
    posterLiveCode: undefined,
    qwPlanId: undefined,
    qrCode: '',
    posterScope: undefined,
    effectiveDate: undefined,
    status: 0,
}
export const templateTwo = {
    posterTime: '',
    posterName: '',
    sysPosterFactoryFormList: [], // 选择工厂列表
    photoMatLibDetailsId: undefined, // 工厂图片
    photoMatLibDetailsUrl: '',
    profileMatLibDetailsId: undefined, // 企业简介
    profileMatLibDetailsDesc: '',
    addressMatLibDetailsId: undefined, // 企业地址
    addressMatLibDetailsDesc: '',
    highlights: '', // 亮点
    highlightsTitle1: '',// 亮点介绍标题
    highlightsTitle2: '',// 亮点介绍
    highlightsTitle3: '',// 亮点介绍
    highlightsTitle4: '',// 亮点介绍
    highlightsTitleDesc1: '',// 亮点介绍正文
    highlightsTitleDesc2: '',// 亮点介绍正文
    highlightsTitleDesc3: '',// 亮点介绍正文
    highlightsTitleDesc4: '',// 亮点介绍正文
    posterLiveCode: undefined, // 海报活码类型
    qwPlanId: undefined, // 计划id
    qrCode: '', // 计划二维码
    posterScope: undefined, // 应用范围
    effectiveDate: undefined, // 有效期
    status: 0, // 海报状态
}
export const templateThree = {
    posterTime: '',
    posterName: '',
    sysPosterFactoryFormList: [], // 选择工厂列表
    photoMatLibDetailsId: undefined, // 工厂图片
    photoMatLibDetailsUrl: '',
    profileMatLibDetailsId: undefined, // 企业简介
    profileMatLibDetailsDesc: '',
    jobRequirementMatLibDetailsId: '', // 招聘要求
    jobRequirementMatLibDetailsDesc: '',
    highlights: '', // 亮点
    highlightsTitle1: '',// 亮点介绍标题
    highlightsTitle2: '',// 亮点介绍
    highlightsTitleDesc1: '',// 亮点介绍正文
    highlightsTitleDesc2: '',// 亮点介绍正文
    posterLiveCode: undefined, // 海报活码类型
    qwPlanId: undefined, // 计划id
    qrCode: '', // 计划二维码
    posterScope: undefined, // 应用范围
    effectiveDate: undefined, // 有效期
    status: 0, // 海报状态
}
export const templateFour = {
    posterName: '',
    posterTime: '',
    sysPosterFactoryFormList: [], // 选择工厂列表
    highlights: '', // 亮点
    highlightsTitle1: '',
    highlightsDesc1: '',
    highlightsDesc2: '',
    posterLiveCode: undefined, // 海报活码类型
    qwPlanId: undefined, // 计划id
    qrCode: '', // 计划二维码
    posterScope: undefined, // 应用范围
    effectiveDate: undefined, // 有效期
    status: 0, // 海报状态
}

// 初始化订单详情数据
export const initOrderInfo: orderInfoModel = {
    contractInfo: null,
    orderNo: '', // 订单编号
    orderDate: '', // 发单日期
    recruitmentChannel: 0, // 招聘渠道
    recruitmentCommissionInfoWz: '', // 招聘提成 外招
    inviteNumberEnabled: 0, // 拟招人数：0-不限，1-限制
    inviteNumber: '', // 拟招人数
    menwomenProportionEnabled: 0, // 男女比例
    menwomenProportionMan: '', // 男比例
    menwomenProportionWomen: '', // 女比例
    noLimitAgeMax: 40, // 性别年龄-不限-最大值
    noLimitAgeMin: 18, // 性别年龄-不限-最小值
    noLimitEnabled: 1, // 性别年龄-不限-开关: string-未选, 1-选中
    sexAndAge: '不限', // 性别年显示值
    nationLimitList: [],  // 民族限制
    recruitmentCommissionMode: 2, // 招聘提成模式 默认为在职时长计提
    recruitmentCommissionConditionInfo: '', // 招聘提成支付条件
    recruitmentCommissionBegin: '', // 招聘提成支付条件
    recruitmentCommissionEnd: '', // 招聘提成支付条件
    recruitmentCommissionMan: '', // 招聘提成男
    recruitmentCommissionWomen: '', // 招聘提成女
    recruitmentCommissionReadonly: 0, // 是否可修改计提模式
    recruitmentCommission: '', // 招聘提成
    deductionFee: '', // 扣费方式
    repastConsumption: '', // 消费方式
    cateringTreatment: '', // 餐饮待遇
    accommodationTreatment: '', // 住宿待遇
    dormitoryConditions: [], // 宿舍条件
    dormitoryStandard: [], // 宿舍标准
    accommodate: '', // 宿舍类型
    accommodationType: '', // 提供住宿
    hydropowerFee: '', // 水电费用
    hydropowerFeeAmount: '', // 水电费用-固定金额
    hydropowerFeeAmountUnit: '1430000212834011122',
    explain: '', // 食宿说明
    borrowInfo: '', // 借支说明
    specialInfo: '', // 特别事项
    orderMode: '', // 发单模式名称
    customerPayDay: '', // 企业发薪日
    companyPayDay: '', // 华辉发薪日
    diffPayDay: '', // 差价补发日期
    employeeDuration: '', // 员工工期
    durationType: null, // 员工工期类型  默认选月数
    durationBegin: '', // 员工工期开始时间
    durationEnd: '', // 员工工期结束时间
    durationMonth: '', //工期月数 
    payEntity: 1, // 1、华辉发薪；2、企业发薪
    orderDeduction: {
        lodgingEnabled: 0, //住宿费-是否启用：0-否, 1-是
        lodgingAmount: '', // 住宿费-金额
        lodgingAmountUnit: '', // 住宿费-补贴金额单位
        lodgingConditionInfo: '', // 住宿费-扣减说明说明
        lodgingIncludeHydropower: 0, // 住宿费-是否含水电

        foodEnabled: 0, //伙食费-是否启用：0-否, 1-是
        foodMode: '', // 伙食费-伙食费-扣减方式
        foodAmount: '', // 伙食费-金额
        foodAmountUnit: '', // 伙食费-补贴金额单位
        foodConditionInfo: '', // 伙食费-扣减说明

        hydropowerEnabled: 0, //水电费-是否启用：0-否, 1-是
        hydropowerMode: '', // 水电费-水电费-扣减方式
        hydropowerAmount: '', // 水电费-金额
        hydropowerAmountUnit: '', // 水电费-单位
        hydropowerConditionInfo: '', // 水电费-扣减说明说明

        insuranceEnabled: 0, //保险费-是否启用：0-否, 1-是
        insuranceAmount: '', // 保险费-金额
        insuranceConditionInfo: '', // 保险费-扣减说明说明

        breachEnabled: 0, // 违约金-是否启用：0-否, 1-是
        breachAmount: '', // 违约金-金额
        breachConditionInfo: '', // 违约金-扣减说明说明

        trainingEnabled: 0, // 培训费-是否启用：0-否, 1-是
        trainingAmount: '', // 培训费-金额
        trainingConditionInfo: '', // 培训费-扣减说明说明

        otherEnabled: 0, // 其他-是否启用：0-否, 1-是
        otherAmount: '', // 其他-金额
        otherConditionInfo: '', // 其他-扣减说明说明

        // 4.0 新增
        socialEnabled: 0, // 社保费-是否开启：0-否, 1-是
        socialAmount: '', // 金额
        socialConditionInfo: '', // 其他-扣减说明说明
    }, // 扣减项
    orderSubsidy: {
        foodEnabled: 0, // 餐饮补贴-是否启用：0-否, 1-是
        foodAmountUnit: '1430000212834011120', // 餐饮补贴-补贴金额单位
        foodAmount: '', // 餐饮补贴-补贴金额
        foodConditionInfo: '',// 餐饮补贴-支付条件

        fareEnabled: 0, // 车费补贴-是否启用：0-否, 1-是
        fareAmount: '', // 车费补贴-补贴金额
        fareConditionInfo: '',// 车费补贴-支付条件

        healthCheckEnabled: 0, // 体检补贴-是否启用：0-否, 1-是
        healthCheckAmount: '', // 体检补贴-补贴金额
        healthConditionInfo: '',// 体检补贴-支付条件

        awardEnabled: 0, // 稳岗奖励-是否启用：0-否, 1-是
        awardAmountUnit: '', // 稳岗奖励-补贴金额单位
        awardAmount: '', // 稳岗奖励-补贴金额
        awardConditionInfo: '',// 稳岗奖励-支付条件

        stayEnabled: 0, // 住宿补贴-是否启用：0-否, 1-是
        stayAmount: '', // 住宿补贴-补贴金额
        stayAmountUnit: '', // 住宿补贴-补贴金额单位
        stayConditionInfo: '',// 住宿补贴-支付条件

        otherSubsidyEnabled: 0, // 其他补贴-是否启用：0-否, 1-是
        otherAmount: '', // 其他补贴-补贴金额
        otherConditionInfo: '',// 其他补贴-支付条件

        nightEnabled: 0, // 夜班补贴-是否启用：0-否, 1-是
        nightAmount: '', // 夜班补贴-补贴金额
        nightConditionInfo: '', //  夜班补贴-支付条件

        fareReimbursementEnabled: 0,
        fareReimbursementAmount: '', // 车费报销金额
        fareReimbursementMileagePrice: '', // 车费报销-按里程计费单价/每公里
        fareReimbursementOnJobDate: '', // 车费报销-发放条件按在职到指定日期
        fareReimbursementOnJobDays: '', // 车费报销-发放条件按在职天数
        fareReimbursementOnJobType: 1, // 车费报销-发放条件: 0-按在职时长，1-在职到指定日期
        fareReimbursementStartingPrice: '', // 车费报销-按里程计费起步价
        fareReimbursementType: 1, //车费报销-: 0-固定金额，1-按里程计费
        fareWorkDay: '', //车费补贴-约束在职天数

        // v3.1 新增
        fareReimbursementPaymentCondition: '',  // 车费报销支付条件
        recommendEnabled: 0, // 推荐费-是否启用，1：是，0：否
        recommendGrantCondition: 2, // 推荐费-发放条件，1：在职时长，2：在职指定日期
        recommendGrantDate: '', // 推荐费-在职日期
        recommendGrantDays: '', // 推荐费-在职天数
        recommendPaymentCondition: 2, // 推荐费-支付条件，1：在职时长，2：指定日期发放
        recommendPaymentDate: '', // 推荐费-支付日期
        recommendPaymentDays: '', // 推荐费-支付浮动天数
        recommendRewardAmount: '', // 推荐费-补贴金额
    }, // 补贴项
    orderStructureCycleList: [], // 薪酬设置list
    trainingEnabled: 0, // 在职培训（1：有，0：无）
    trainingDays: '', // 培训天数
    trainingMode: '', // 培训期记薪方式
    trainingPrice: '', // 培训期记薪-底薪工价
    trainingPriceMan: '', // 培训期记薪-男工价
    trainingPriceWomen: '', // 培训期记薪-女工价
    trainingConditionInfo: '', // 培训期记薪-支付条件
    // v2.6.0 新增
    recruitmentCommissionDateType: 4, // 计提时长类型
    recruitmentCommissionDateNumber: undefined, // 计提时长
    recruitmentCommissionShop0: '', // 超一类门店
    recruitmentCommissionShop1: '', // 一类门店
    recruitmentCommissionShop2: '', // 二类门店
    recruitmentCommissionShop3: '', // 三类门店
    recruitmentCommissionShop4: '', // 四类门店
    recruitmentCommissionConditionDay: 7, //  入职条件计提 - 支付条件天数
    // v3.0新增
    examInfo: '', // 体检说明
    interviewInfo: '',  // 面试资料说明
    durationDay: '', // 员工工期 天数
    // 3.2新增
    filesAttachmentInfoList: [],
    entryTimeDescription: '',
    // 3.3新增
    manEnabled: 1, // 性别年龄-男-开关: string-未选, 1-选中
    manAgeMax: 40, // 性别年龄-男-最大值
    manAgeMin: 18, // 性别年龄-男-最小值
    manHeightEnabled: 0, // 男身高开关，0：不限，1：限制
    manHeightMax: 200, // 男身高最大值
    manHeightMin: 130, // 男身高最小值
    manWeightEnabled: 0, // 男体重开关，0：不限，1：限制
    manWeightMax: 100, // 男体重最大值
    manWeightMin: 30, // 男体重最小值
    womanEnabled: 1, // 性别年龄-女-开关: string-未选, 1-选中
    womanAgeMax: 40, // 性别年龄-女-最大值
    womanAgeMin: 18, // 性别年龄-女-最小值
    womanHeightEnabled: 0, // 女身高开关，0：不限，1：限制
    womanHeightMax: 200, // 女身高最大值
    womanHeightMin: 130, // 女身高最小值
    womanWeightEnabled: 0, // 女体重开关，0：不限，1：限制
    womanWeightMax: 100, // 女体重最大值
    womanWeightMin: 30, // 女体重最小值
    regionLimit: JSON.stringify(regionLimitOptions),
    areaLimitText: '不限',
    // 4.0新增
    examEnabled: 0, // 是否体检；0：无需体检，1：需体检
    examCostType: 0, // 体检费用类型，0：免费，1：自费
    examCostMan: '', // 体检费用-男
    examCostWoman: '', // 体检费用-女  
    examCostExpense: 0,  // 体检是否报销；0：否，1：是
}

// 初始化合同数据
export const initContarctData: contractFormModel = {
    type: null,   // 合同类型
    customerId: null,   // 所属客户
    departmentId: null,   // 所属部门
    postId: null,
    employmentMode: null,
    companyId: null, // 签约主体
    contractDate: null, // 合同日期
    dateBegin: null,
    dateEnd: null,
    recruitmentBegin: null,
    recruitmentEnd: null,
    ownerUser: null,
    ownerUserName: null,
    peopleQtyEnabled: 0,
    peopleQty: null,
    feeStructure: {
        unitCost: null, // 单价
        stablePosition: null, // 稳岗,
        adminCost: null,  // 管理费用,
        equalPayWork: null, // 同工同酬,
        fareSubsidy: null, // 车费补贴,
        cateringSubsidy: null, // 餐饮补贴,
        bonus: null, // 奖金,
        premium: null, // 保险费,
        adminPay: null, // 管理员工资,
        bodyInspectionFee: null,  // 体检费,
        nucleicAcidFee: null, // 核酸费,
        nightSubsidy: null, // 夜班补贴,
        environmentSubsidy: null, // 环境补贴,
        otherSubsidy: null, // 其他补贴
    },
    invoiceRemark: null,//开票规则-备注
    invoiceTaxesType: null,//开票规则-纳税形式
    invoiceType: null,//开票规则-发票类型
    otherMatters: null,//补充事项
    deductionFee: null, // 扣费方式
    repastConsumption: null, // 消费方式
    cateringTreatment: null, // 餐饮待遇
    accommodationTreatment: null, // 住宿待遇
    accommodate: null, // 提供住宿
    accommodationType: null, // 宿舍类型
    dormitoryConditions: [],// 宿舍条件
    dormitoryStandard: [], // 宿舍标准
    hydropowerFee: null, // 水电费用
    hydropowerFeeAmount: null, // 水电费用-固定金额
    hydropowerFeeAmountUnit: null, // 水电费用-固定金额单位
    explain: null, // 食宿说明

}

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface Iloading {
    loading: boolean;
    advanceCount: number;
    title: string,
    dataExprotOpen: boolean;
}

const initialState: Iloading = {
    loading: false,
    advanceCount: 0,
    title: '加载中...',
    dataExprotOpen: false,

}

export const loadingSlice = createSlice({
    name: 'loading',
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload
        },
        setAdvanceCount: (state, action: PayloadAction<number>) => {
            state.advanceCount = action.payload;
        },
        setTitle: (state, action: PayloadAction<string>) => {
            state.title = action.payload;
        },
        setDataExprotOpen: (state, action: PayloadAction<boolean>) => {
            state.dataExprotOpen = action.payload;
        },
    },
})

export const { setLoading, setAdvanceCount, setTitle, setDataExprotOpen } = loadingSlice.actions

export default loadingSlice.reducer
export enum siteTabEnum {
    signup = "signup", //求职报名
    storeRegister = "storeRegister", //门市登记
    brushCar = "brushCar", //刷证上车
    brushQuery = "brushQuery" // 刷证查询
}
export enum btnAliasEnum {
    addBtn = "addBtn", //新增
    addBtnNew = "addBtnNew", //新增(新)
    basicsBtn = "basicsBtn", //基础配置
    editBtn = "editBtn", //编辑
    deleteBtn = 'deleteBtn', // 删除
    signBtn = "signBtn", // 标记
    lockBtn = 'lockBtn', // 报名锁定
    configuration = 'configuration', // 比例配置
    examine = 'examine', // 批量审核 单个审核
    export = 'export', // 导出
    import = 'import', // 导入
    examineLimit = 'examineLimit',
    customerAdmin = 'customerAdmin', //驻厂管理员
    accounting = 'accounting', //核算
    revoke = 'revoke', //撤销
    view = 'view', //view

}